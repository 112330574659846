import React, { Component } from 'react';

class Blog extends Component {
   render() {

      if (this.props.data) {
         var blog = this.props.data.blog.map(function(blog){
            return <li key={blog.subject}>
               <blockquote>
                  <h6>{blog.date}</h6>
                  <p>{blog.text}</p>
               </blockquote>
            </li>
         })
      }

      return (
         <section id="blog">
            <div className="text-container">
               <div className="row">

                  <div className="two columns header-col">
                     <h1><span>My Journey!</span></h1>
                  </div>

                  <div className="ten columns flex-container">
                     <ul className="slides">
                        {blog}
                     </ul>
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default Blog;
